<template>
    <div class="user-info">
            <div class="title" @click="showEdit=1" :class="{active:showEdit==1}">我的信息</div>
            <div class="title" @click="showEdit=2" :class="{active:showEdit==2}">修改密码</div>
            <div class="title" @click="showEdit=3" :class="{active:showEdit==3}">更换手机</div>
            <hr>
        <div class="edit"  v-show="showEdit==1">
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">用户名：</a-col>
                <a-col flex="400px">
                    <span v-if="editStatus===0">{{userInfo.realName}}</span>
                    <a-input v-else v-model="userInfo.realName" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">邮箱：</a-col>
                <a-col flex="400px">
                    <span v-if="editStatus===0">{{userInfo.email}}</span>
                    <a-input v-else v-model="userInfo.email" :disabled="true" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">机构：</a-col>
                <a-col flex="400px">
                    <span v-if="editStatus===0">{{userInfo.organName}}</span>
                    <a-input v-else v-model="userInfo.organName" :disabled="true" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">电话：</a-col>
                <a-col flex="400px">
                    <span>{{userInfo.phone}}</span>
                </a-col>
            </a-row>
        </div>
        <div class="edit"  v-show="showEdit==2">
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">原始密码：</a-col>
                <a-col flex="400px">
                    <a-input-password v-model="pwd" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">新密码：</a-col>
                <a-col flex="400px">
                    <a-input-password v-model="newPwd" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">确认密码：</a-col>
                <a-col flex="400px">
                    <a-input-password v-model="enterNewPwd" />
                </a-col>
            </a-row>
            <div class="btns">
                <a-button type="primary" @click="infoCenterChanggePwdByPwd">提交</a-button>
                <a-button @click="cancel(1)">取消</a-button>
        </div>
        </div>
        <div class="edit"  v-show="showEdit==3">
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">新手机号码：</a-col>
                <a-col flex="400px">
                    <a-input v-model="newPhone" />
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">验证码：</a-col>
                <a-col flex="282px">
                    <a-input v-model="verifyCode" />
                </a-col>
                <a-col flex="100px">
                    <a-button type="primary" @click="sendCode">发送验证码</a-button>
                </a-col>
            </a-row>
            <div class="btns">
                <a-button type="primary" @click="changeNewPhone">提交</a-button>
                <a-button @click="cancel(2)">取消</a-button>
        </div>
        </div>
    </div>
</template>

<script>
import { messageTips,isPhone } from '@/until/tools';
export default {
    name:'Info',
    data(){
        return {
            showEdit:'1',
            userInfo:{},
            editStatus:0,
            newPwd:'',
            pwd:'',
            enterNewPwd:'',
            newPhone:'',
            verifyCode:'',
        }
    },
    created() {
        this.getUserInfo();
    },
    methods:{
        getUserInfo() {
            this.$http.getUserInfo().then(res=>{
                if(res.success){
                    this.userInfo = res.userInfo;
                }
            });
        },
        infoCenterChanggePwdByPwd(){
            if (this.pwd === "") {
                messageTips("请输入原始密码", 2);
                return false;
            }
            if (this.newPwd === "") {
                messageTips("请输入新密码", 2);
                return false;
            }
            if (this.enterNewPwd === "") {
                messageTips("请再次输入新密码", 2);
                return false;
            }
            if (this.enterNewPwd !==this.newPwd) {
                messageTips("两次密码不一致，请重新确认", 2);
                return false;
            }
            let params ={
                newPwd:this.enterNewPwd,
                pwd:this.pwd
            }
            this.$http.infoCenterChanggePwdByPwd(params).then(res=>{
                if(res.success){
                    messageTips(res.message, 3);
                    this.pwd="",
                    this.newPwd="",
                    this.enterNewPwd=""
                }else{
                    messageTips(res.message,2)
                }
            })
        },
        sendCode(){
            if (this.newPhone === "") {
                messageTips("请输入手机号码", 2);
                return false;
            }
            if (!isPhone(this.newPhone)) {
                messageTips("手机号码格式不正确", 2);
                return false;
            }
            let params = {
                account: this.newPhone,
            };
            this.$http.getSign(params).then((res) => {
                if (res) {
                    if (res.sign) {
                        this.$http.sendCode({ sign: res.sign, ...params }).then((res) => {
                            this.verifyCode=res.verifyCode
                        });
                    } else {
                        messageTips(res.message, 1);
                    }
                }
            });
        },
        changeNewPhone(){
            if (this.newPhone === "") {
                messageTips("请输入手机号码", 2);
                return false;
            }
            if (!isPhone(this.newPhone)) {
                messageTips("手机号码格式不正确", 2);
                return false;
            }
            if(this.verifyCode===""){
                messageTips("请输入验证码", 2);
                return false;
            }
            let params2 = {
                verifyCode:this.verifyCode,
                phone:this.newPhone
            }
            this.$http.changeNewPhone(params2).then(res=>{
                if(res.success){
                    messageTips(res.message, 3);
                    this.verifyCode="",
                    this.newPhone=""
                }else{
                    messageTips(res.message, 2);
                }
            })
        },
        save() {
            let params = {
                name:this.userInfo.realName,
                email:this.userInfo.email,
            }
            this.$http.userUpdate(params);
        },
        cancel(val) {
             if(val==1){
                this.pwd="",
                this.newPwd="",
                this.enterNewPwd=""
             }else{
                this.verifyCode="",
                this.newPhone=""
             }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/_variables.scss';
.user-info {
    padding:30px;
    .title{
        display: inline-block;
        margin-right: 30px;
        font-size:18px;
        &:hover{
            color:#fba951;
            cursor: pointer;
        }
    }
    hr{
        margin:10px auto 20px;
        height: 1px;
        border:none;
        border-bottom:1px solid #DaDaDa; 
    }
    .active{
        color:#fba951;
    }
    .edit{
        margin-bottom: 40px;
    }
    .btns{
        margin:60px 0 0 160px;
    }
    button {
        margin-right:20px;
        width: 100px;
    }
    .ant-btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
    }
    .ant-btn-background-ghost.ant-btn-primary {
        color:$primary-color;
        border-color: $primary-color;
    }
}
</style>