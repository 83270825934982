<template>
	<a-layout style="height:100%;overflow:hidden">
		<a-layout-header style="height:74px;">
			<Header />
		</a-layout-header>
		<a-layout>
            <a-layout-sider :style="pageLayoutSiderStyle">
                <div class="sider-inner" :style="{width:showSide?'100%':'10px'}">
                    <div class="switch" @click="switchSider">
                        <a-icon type="backward" v-if="showSide" />
                        <a-icon type="forward" v-else />
                    </div>
                    
                    <ul class="side-nav" v-if="showSide">
                        <li>
                            <router-link to="/user_info">
                                <i class="iconfont icon-user"></i>
                                <span>基本信息</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user_collections">
                                <i class="iconfont icon-htmal5icon24"></i>
                                <span>我的收藏</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user_series" :class="{'router-link-active':currentUrl.indexOf('user_series')!==-1}">
                                <i class="iconfont icon-shijianxulie"></i>
                                <span>我的序列</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user_history">
                                <i class="iconfont icon-lishi"></i>
                                <span>浏览历史</span>
                            </router-link>     
                        </li>
                    </ul>

                </div>                
            </a-layout-sider>
            <a-layout-content>
                <router-view />
            </a-layout-content>
        </a-layout>
	</a-layout>
</template>

<script>
import Header from '../../components/Header.vue';
import {setCube} from '../../until/data_tools'
import {pageLayoutSiderStyle,pageLayoutSiderHideStyle} from '../../until/global_style';
export default {    
	components:{Header},
	data() {
		return {   
            showSide:true,         
            pageLayoutSiderStyle:pageLayoutSiderStyle,
        }
    },
    computed:{
        currentUrl() {
            return this.$route.path;
        }
    },
	created() {
		this.$http.getUserInfo().then(res=>{
			if(res.userInfo) {
				let userInfo = res.userInfo;
				let cubes = setCube(res.userInfo.metaCubeList);
				this.$store.commit('setCubes',cubes);
				this.$store.commit('setUserInfo',userInfo);
			}
		});
    },
    methods:{
        switchSider() {
            this.showSide = !this.showSide;
            if(this.showSide) {
                this.pageLayoutSiderStyle = pageLayoutSiderStyle;
            } else {
                this.pageLayoutSiderStyle = pageLayoutSiderHideStyle
            }
        },
    }
}
</script>
<style lang="scss">
@import '../../scss/_variables.scss';
.side-nav {
    padding:20px 0;
    li {
        height:46px;
        line-height: 46px;
        a {
            display: block;
            width:100%;
            height:100%;
            padding-left: 30px;
            color:#222;
            .iconfont {
                margin-right: 10px;
            }
        }
        .router-link-active {
            background:$primary-color;
            color:#fff;
        }
    }
}
</style>