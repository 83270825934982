<template>
    <div>
        <div class="logo">
            <img src="@/assets/szlogo2.png" alt="">
        </div>
        <div class="cubes">
            <div class="h2">全部数据库</div>
            <a-icon type="caret-down" />
            <div class="cube-list">
                <div class="item" v-for="item in cubes" :key="item.cubeId" @click="visitCube(item)">
                    {{item.cubeNameZh}}
                </div>
            </div>
        </div>
        <div class="header-nav">
            <div class="navList">
                <div style="float: left;">
                    <!-- <router-link :to="dataUrl">数据查询</router-link>
                    <a-divider type="vertical" />
                    <router-link to="/datas_articles">原文查看</router-link> -->
                </div>
                <div class="username">
                    <a :title="userInfo.realName">
                        {{ userInfo.realName|getTxtShow(5)}}
                    </a>
                    <a-dropdown placement="bottomCenter">
                        <span class="ant-dropdown-link fr" style="cursor:pointer" @click="e => e.preventDefault()">
                            <a-icon type="caret-down" />
                        </span>
                        <template #overlay>
                            <a-menu style="margin-top:6px">
                                <a-menu-item>
                                    <router-link to="/user_info">基本信息</router-link>
                                </a-menu-item>
                                <a-menu-item>
                                    <router-link to="/user_collections">我的收藏</router-link>
                                </a-menu-item>
                                <a-menu-item>
                                    <router-link to="/user_series">我的序列</router-link>
                                </a-menu-item>
                                <a-menu-item>
                                    <router-link to="/user_history">浏览历史</router-link>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="logout">退出</a>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>
                <!-- <a-divider type="vertical" />
                <router-link to="/datas_reback">意见反馈</router-link> -->
            </div>
        </div>
        
        <div class="search">
            <a-input-search placeholder="请输入关键词" size="large" v-model="keywords" @search="onSearch">
                <a-button type="primary" slot="enterButton">
                    <a-icon type="search" />
                    搜索
                </a-button>
            </a-input-search>

            <a-button type="primary" class="cloud-btn" ghost size="large" @click="goCloud">
                云分析平台
            </a-button>
        </div>
    </div>
</template>

<script>
import { delCookie, delSessionItem } from '@/until/tools'
export default {
    data() {
        return {
            keywords:this.$route.query.keywords||''
        }
    },
    filters: {
        getTxtShow(value, num) {
        if (!value) return "";
        if (value.length > num) {
            return value.slice(0, num) + "...";
        }
        return value;
        },
    },
    computed:{
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
        cubes() {
            let arr = this.$store.getters.getCubes;
            console.log(arr)
            let result = [];
            arr.forEach(item=>{
                if(item.children.length>0) {
                    result.push(item);
                }
            });            
            return result;
        },
        dataUrl() {
            let obj = {
                path:'/datas_data'
            }
            let params;
            if(this.cubes[0]) {
                if(this.cubes[0].children&&this.cubes[0].children[0]) {
                    params = {
                        pCubeId:this.cubes[0].cubeId,
                        cubeId:this.cubes[0].children[0].cubeId
                    }
                } else {
                    params = {
                        cubeId:this.cubes[0].cubeId
                    }   
                }
            }
            obj.query = params;
            return obj;
        }
    },
    methods:{
        logout() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要退出吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    // setCookie('loginTargetUrl',that.$route.path,{time:1});
                    delCookie('bd_sid');
                    delCookie('canInput');
                    delSessionItem('dims');
                    that.$router.push('/login');
                }
            })
        },
        onSearch(e) {
            if(e==='') {
                return false;
            }
            this.$router.push({
                path:'/datas_search',
                query:{keywords:e}
            })
        },
        visitCube(item) {
            let params;
            if(item.children&&item.children[0]) {
                params = {
                    pCubeId:item.cubeId,
                    cubeId:item.children[0].cubeId
                }
            } else {
                 params = {
                    cubeId:item.cubeId
                }   
            }
            delSessionItem('dims');
            this.$router.push({
                path:'/datas_data',
                query:params
            });
        },
        goCloud() {
            this.$router.push({path:'/cloud'})
        }      
    }
}
</script>

<style lang="scss">
.search {
    .ant-input {
        background:none;
        color:#fff;
    }
}
</style>
<style lang="scss" scoped>
/deep/.ant-dropdown-menu-item{
  padding: 0px 20px !important;
  line-height: 29px !important;
  border-bottom: 1px solid #e8e8e8;
  &:last-child{
    border:none;
  }
  >a{
    margin: 0;
    padding: 0
  }
}
@import '../scss/datas/header.scss';
</style>